import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import ConsentManagerWrapper from '~/components/Segment/ConsentManager'
import './static/fonts/fonts.css'
import './src/styles/global.css'
import { addProductUrl } from '~/utils/addProductUrl'
const { FilterProvider } = require('~/contexts/FilterContext')

const addScript = (url, id, onLoad, klarna) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  if (klarna) {
    script.dataset.clientId = '37d73d47-c782-563a-b44d-688fd5041e4f'
  }
  if (id) script.id = id

  if (onLoad) {
    script.onload = onLoad
  }

  document.body.appendChild(script)
}

export const wrapPageElement = ({ element }) => (
  <Fragment>
    <Global
      styles={() => ({
        html: {
          fontSize: '62.5%',
          scrollBehavior: 'smooth',
          textSizeAdjust: '100%'
        },
        body: {
          minHeight: '-webkit-fill-available',
          fontSize: '1.7rem', // 17px
          textRendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          fontSmooth: 'antialiased'
        }
      })}
    />
    <ConsentManagerWrapper>
      <FilterProvider>{element}</FilterProvider>
    </ConsentManagerWrapper>
  </Fragment>
)

export const onClientEntry = () => {
  // include custom properties inside filtered events using Segment analytics middleware
  addProductUrl();

  window.onload = () => {
    addScript(
      `https://na-library.klarnaservices.com/lib.js`,
      'klarna',
      null,
      true
    )
    if (process.env.GATSBY_YOTPO_APP_KEY) {
      // This will load for all pages, giving us the side widget with testimonials for all Super73, for the product specific reviews refer to ProductReviews (~/components/Product/Reviews/index.jsx)
      addScript(
        `https://staticw2.yotpo.com/${process.env.GATSBY_YOTPO_APP_KEY}/widget.js`,
        'yotpo-widget-script'
      )
    }

    if (process.env.GATSBY_TALKABLE_SITE_ID) {
      const onTalkableLoad = () => {
        window._talkableq = window._talkableq || []
        window._talkableq.unshift([
          'init',
          { site_id: process.env.GATSBY_TALKABLE_SITE_ID }
        ])
        window._talkableq.push(['register_affiliate', {}])
      }
      addScript(
        `https://d2jjzw81hqbuqv.cloudfront.net/integration/clients/${process.env.GATSBY_TALKABLE_SITE_ID}.min.js`,
        onTalkableLoad
      )
    }
    addScript(`https://harpocrates.ethyca.com/emp.js`)
    addScript(
      'https://static.zdassets.com/ekr/snippet.js?key=c91b7ea9-182f-4963-a1e2-26c19d1d993f',
      'ze-snippet'
    )
    addScript(
      '//cdn.jsdelivr.net/npm/sweetalert2@9.10.12/dist/sweetalert2.all.min.js'
    )

    window.__lo_site_id = 177053
    addScript('https://d10lpsik1i8c69.cloudfront.net/w.js')
  }
}
