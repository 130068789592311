module.exports.addProductUrl = () => {
    try {
        const { analytics, location: { origin }} = window;
        const allowedEvents = ['Product Viewed', 'Product Clicked'];

        analytics.addSourceMiddleware(({ payload, integration, next }) => {
            if (allowedEvents.includes(payload.obj.event) && !payload.obj.properties.product_url) {
                payload.obj.properties.product_url = `${origin}/products/${payload.obj.properties.product_slug}`;
            }
            next(payload);
        });
    } catch (e) {
        console.log('----> error:', e)
    }
}