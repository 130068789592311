module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":"","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Mw19fDAa7e5Jr65FAuhBf1AflhoCOyTh","devKey":"Mw19fDAa7e5Jr65FAuhBf1AflhoCOyTh","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-performance/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"tu8gkjqcgjw9","accessToken":"KjEjez_o-xCw9mNBSOcv1u8692L2lvMpx9H_8C2EbF8","host":"cdn.contentful.com","environment":"master"},"sitemapConfig":{"output":"/","excludes":["/cart","/order/confirmation","/pages/repus-dashboard","/pages/referral-dashboard","/products/chord-product-placeholder","products/super73-youth","products/super73-c1x"]},"robotsConfig":{"sitemap":"https://super73.com/sitemap-0.xml"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},"siteMetadata":{"title":"Super73","siteUrl":"https://super73.com","description":"Home to the Original Super73® Electric Motorbike, the Super73® S-Series and the Super73® Z-Series. Fun electric bikes full of features, ready for adventure. From Southern California since May 2016.","social":{"facebook":"https://www.facebook.com/super73","instagram":"https://www.instagram.com/super73","twitter":"https://www.twitter.com/super73","vimeo":"https://vimeo.com/user107620059","youtube":"https://www.youtube.com/super73","tiktok":"https://www.tiktok.com/@super73"}},"segmentConfig":{"prodKey":"Mw19fDAa7e5Jr65FAuhBf1AflhoCOyTh","devKey":"Mw19fDAa7e5Jr65FAuhBf1AflhoCOyTh","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"white","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N8BLTRG","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
