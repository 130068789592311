/**@jsx jsx */
import React, { Fragment } from 'react'
import { ConsentManager } from '@segment/consent-manager'
import inEU from '@segment/in-eu'
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'

const bannerContent = (
  <span>
    We use cookies (and other similar technologies) to collect data to improve
    your experience on our site.
    <br />
    If you “Allow all” we and our partners will also use cookies that collect
    additional data to further enhance your experience, help us analyze site
    usage and deliver personalized advertising.
  </span>
)

const bannerActionsBlock = ({ acceptAll, denyAll }) => (
  <div>
    <button
      type="button"
      onClick={acceptAll}
      sx={{
        backgroundColor: 'white',
        color: 'black',
        mr: '5px',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '2px',
        cursor: 'pointer'
      }}
    >
      Allow all
    </button>
    <button
      type="button"
      onClick={denyAll}
      sx={{
        backgroundColor: 'transparent',
        color: 'white',
        padding: '5px 10px',
        border: 'none',
        cursor: 'pointer'
      }}
    >
      Deny all
    </button>
  </div>
)

const preferencesDialogContent = (
  <div>
    <p>
      We use data collected by cookies and JavaScript libraries to make this
      site work as intended. We and the partners identified below would also
      like to use cookies to improve your browsing experience, analyze site
      traffic, deliver personalized advertisements, and increase the overall
      performance of our site. If you choose to allow Marketing and Analytics or
      Advertising cookies, you are directing us to make information about your
      use of our site available to those partners, some of whom may combine it
      with information they have collected about you elsewhere and use it to
      deliver personalized advertising to you on this site or other sites.
    </p>
    <p>
      By using our website, you’re agreeing to our{' '}
      <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">
        Website Data Collection Policy
      </a>
      .
    </p>
    <p>
      The table below outlines how we use this data by category. To opt out of a
      category of data collection, select “No” and save your preferences.
    </p>
  </div>
)

const cancelDialogContent = (
  <div>
    Your preferences have not been saved. By continuing to use our website,
    you’re agreeing to our{' '}
    <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Website Data Collection Policy
    </a>
    .
  </div>
)

const getSegmentWriteKey = () => {
  return process.env.NODE_ENV === 'production' &&
    process.env.CONTEXT === 'production'
    ? process.env.GATSBY_SEGMENT_PRODUCTION_WRITE_KEY
    : process.env.GATSBY_SEGMENT_DEV_WRITE_KEY
}

const onError = error => {
  console.log('ConsentManagerBanner error', error)
}

const shouldRequireConsent = () => {
  if (process.env.GATSBY_SEGMENT_SHOULD_REQUIRE_CONSENT === 'true') {
    return true
  }

  return inEU()
}

/**
 * This file integrates Segment's consent-manager to protect our visitors privacy.
 *
 * See https://github.com/segmentio/consent-manager
 */
const ConsentManagerWrapper = ({ children }) => {
  const writeKey = getSegmentWriteKey()
  return (
    <Fragment>
      {children}
      <div
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          left: 0,
          zIndex: [999999, 999997],
          '& > div > div': {
            flexDirection: 'row-reverse',
            pr: '8px',
            pl: '40px'
          },
          '[title="Close"]': {
            left: '8px',
            right: 'auto'
          }
        }}
      >
        <ConsentManager
          writeKey={writeKey}
          shouldRequireConsent={shouldRequireConsent}
          implyConsentOnInteraction={false}
          bannerContent={bannerContent}
          bannerSubContent="You can learn more and manage your cookie preferences here!"
          bannerActionsBlock={bannerActionsBlock}
          bannerHideCloseButton={false}
          bannerAsModal={false}
          preferencesDialogTitle="Website Data Collection Preferences"
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle="Are you sure you want to cancel?"
          cancelDialogContent={cancelDialogContent}
          onError={onError}
          bannerBackgroundColor="black"
        />
      </div>
    </Fragment>
  )
}

export default ConsentManagerWrapper
