import React, { useState } from 'react'

const FilterContext = React.createContext()

export const FilterProvider = ({ children }) => {
  const [activeFilters, setActiveFilters] = useState([])

  return (
    <FilterContext.Provider value={[activeFilters, setActiveFilters]}>
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContext
