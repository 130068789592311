export default {
  /**
   * Breakpoints by device
   * Format: [M,T,SL,NL,D], first position is always mobile.
   * 0 - Mobile 0-575,
   * 1 - Tablet 576-991
   * 2 - Small Laptops 992-1199
   * 3 - Normal Laptops  1201-1599
   * 4 - Big Laptop / desktop 1600+,
   */
  breakpoints: ['576px', '768px', '1024px', '1920px'],
  fontSizes: [
    '1rem', // 0
    '1.2rem', // 1
    '1.3rem', // 2
    '1.5rem', // 3
    '1.8rem', // 4
    '2rem', // 5
    '2.5rem', // 6
    '3rem', // 7
    '4rem', // 8
    '4.5rem', // 9
    '5rem', // 10
    '6rem' // 11
  ],
  space: [
    0, // 0
    4, // 1
    8, // 2
    16, // 3
    32, // 4
    52, // 5
    64, // 6
    128, // 7
    256, // 8
    512 // 9
  ],
  fonts: {
    body: '"Neue Haas GDP", system-ui, sans-serif',
    heading: '"Neue Haas GDP", system-ui, sans-serif',
    monospace: '"Ministry", monospace',
    primary: '"Neue Haas GDP", system-ui, sans-serif',
    secondary: '"Helvetica Neue", system-ui, sans-serif'
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
    medium: 500,
    light: 300,
    normal: 400
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  colors: {
    white: '#ffffff',
    black: '#000000',
    black40: 'rgba(0, 0, 0, 0.4)',
    black70: 'rgba(0, 0, 0, 0.7)',
    grey70: '#a3a3a370',
    gray70: '#a3a3a370',
    red: '#d02e2e',
    linkBlue: '#007bbd',
    // Default scheme: black on white
    // Inverted scheme: white on black
    text: '#000000',
    textMuted: '#575757',
    textInverted: '#d0d0d0',
    textMutedInverted: '#8d8d8d',
    border: 'rgba(0,0,0,0.5)',
    borderInactive: '#707070',
    borderInverted: 'rgba(251, 251, 251, 0.5)',
    borderInactiveInverted: '#b9b9b9',
    background: '#FFFFFF',
    backgroundInverted: '#363636',
    primary: '#000000',
    secondary: '#38A169',
    accent: '#000000',
    muted: '#F0FFF4',
    errorDark: '#B30909',
    neutral: '#595959',
    card: '#505050'
  },
  zIndices: {
    navBar: 10
  },
  styles: {
    root: {
      variant: 'text.body',
      a: { color: 'inherit', textDecoration: 'none' }
    }
  },
  buttons: {
    clear: {
      appearance: 'none',
      border: 0,
      fontFamily: 'inherit',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
      color: 'inherit',
      borderRadius: 0,
      borderBottom: '1px solid transparent',
      backgroundColor: 'transparent',
      letterSpacing: 'inherit',
      minWidth: '3.2rem',
      ':disabled': {
        cursor: 'pointer'
      },
      ':hover': {
        borderBottom: '1px solid'
      }
    },
    primary: {
      '--background-color': theme => theme.colors.black,
      '--color': theme => theme.colors.white,
      '--border-color': theme => theme.colors.border,
      backgroundColor: 'var(--background-color)',
      border: '1px solid',
      borderColor: 'var(--border-color)',
      borderRadius: '6px',
      color: 'var(--color)',
      cursor: 'pointer',
      fontFamily: 'secondary',
      fontSize: [1, '13px', '15px'],
      letterSpacing: 'inherit',
      height: ['33px', null, '44px'],
      lineHeight: ['33px', null, '44px'],
      padding: '0 22px',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      '&:disabled': {
        cursor: 'default'
      }
    },
    secondary: {
      '--background-color': 'transparent',
      '--color': theme => theme.colors.black,
      '--border-color': theme => theme.colors.border,
      backgroundColor: 'var(--background-color)',
      border: '1px solid',
      borderColor: 'var(--border-color)',
      borderRadius: '6px',
      color: 'var(--color)',
      cursor: 'pointer',
      fontFamily: 'secondary',
      fontSize: [1, '13px', '15px'],
      letterSpacing: 'inherit',
      height: ['33px', null, '44px'],
      lineHeight: ['33px', null, '44px'],
      padding: '0 22px',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      transition: 'all 0.2s ease',
      '&:hover': {
        '--background-color': theme => theme.colors.black,
        '--color': theme => theme.colors.background,
        '--border-color': theme => theme.colors.black
      },
      '&:disabled': {
        cursor: 'default'
      }
    },
    inverted: {
      variant: 'buttons.primary',
      '--color': theme => theme.colors.white,
      '--border-color': theme => theme.colors.white,
      width: 'auto',
      '&:hover': {
        '--background-color': theme => theme.colors.white,
        '--color': theme => theme.colors.black,
        '--border-color': theme => theme.colors.white
      }
    },
    iconOnly: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '3.2rem',
      minHeight: '3.2rem',
      backgroundColor: 'transparent',
      cursor: 'pointer'
    },
    select: {
      border: 'none',
      backgroundColor: 'inherit',
      color: 'black',
      fontSize: [0, 2, 3],
      padding: ['5px 5px', 'unset'],
      '&:focus': {
        outline: 'none'
      }
    }
  },
  links: {
    small: {
      fontSize: [2],
      borderBottom: '1px solid transparent',
      cursor: 'pointer',
      '&:hover': {
        borderBottom: '1px solid black'
      }
    }
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted'
    }
  },
  layout: {
    container: {
      '--horizontal-inset': ['1.9rem', null, '3.2rem', '4.5rem', '8.3rem'],
      '--width-constraint': '1920px',
      width: '100%',
      maxWidth: 'var(--width-constraint)',
      padding: '0 var(--horizontal-inset)',
      margin: 'auto'
    },
    tablet: {
      margin: 'auto',
      maxWidth: 'calc(1600px)',
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%'
    },
    fullWidth: {
      '--horizontal-inset': ['1.9rem', null, '3.2rem', '4.5rem', '8.3rem'],
      '--width-constraint': '1920px',
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%'
    },
    small: {
      '--horizontal-inset': ['1.9rem', null, '3.2rem', '8.3rem'],
      '--width-constraint': '1024px',
      maxWidth: 'var(--width-constraint)',
      padding: '0 var(--horizontal-inset)',
      overflow: 'hidden',
      width: '100%'
    }
  },
  responsive: {
    mobile: {
      display: ['block', 'none']
    },
    mobileTablet: {
      display: ['block', 'block', 'none']
    },
    tabletUp: {
      display: ['none', 'block']
    },
    smallLaptopUp: {
      display: ['none', 'none', 'block']
    },
    normalLaptopUp: {
      display: ['none', 'none', 'none', 'block']
    },
    desktopUp: {
      display: ['none', 'none', 'none', 'none', 'block']
    }
  },
  text: {
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      letterSpacing: '1px',
      fontSize: [3, 4, 5]
    },
    caps: {
      textTransform: 'uppercase'
    },
    formInput: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    formLabel: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textTransform: 'uppercase'
    },
    formError: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textTransform: 'uppercase',
      color: 'errorDark'
    },
    link: {
      color: '#007bbd',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textDecoration: 'underline',
      letterSpacing: '2px',
      textTransform: 'none'
    },
    h1: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'normal',
      fontSize: 8
    },
    h2: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'normal',
      fontSize: [6, 7, 8]
    },
    h3: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'normal',
      fontSize: 3
    },
    h4: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'normal',
      fontSize: 2
    },
    h5: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'normal',
      fontSize: 1
    },
    navLink: {
      fontFamily: 'primary',
      fontSize: ['1.7rem', null, 1, 4],
      textTransform: 'uppercase',
      color: 'inherit',
      lineHeight: '3rem',
      padding: ['0 0.8rem', '0'],
      letterSpacing: '1.6px'
    },
    small: {
      fontFamily: 'primary',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: [2, 3],
      textTransform: 'uppercase',
      letterSpacing: '1.6px'
    },
    error: {
      color: 'red',
      fontSize: [2, 3]
    },
    title: {
      fontSize: [6, 8],
      pb: [3, 4],
      mb: [4, 6],
      display: 'inline-block',
      position: 'relative',
      textAlign: 'center',
      '::after': {
        display: 'block',
        content: '""',
        width: '30%',
        height: '1px',
        background: 'black',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)'
      }
    },
    tableHeader: {
      fontSize: ['1rem', '1.5rem'],
      textTransform: 'uppercase',
      fontWeight: '500',
      whiteSpace: 'nowrap'
    }
  },
  list: {
    bare: {
      listStyleType: 'none',
      padding: 0
    }
  },
  forms: {
    label: {
      fontSize: '1.4rem',
      fontWeight: 'light',
      transition: '0.25s ease',
      marginBottom: '2px',
      marginTop: '16px'
    },
    input: {
      padding: '10px 15px',
      fontSize: '1.6rem',
      fontWeight: 'light',
      fontFamily: 'body',
      letterSpacing: '0.1rem',
      borderRadius: '5px',
      border: '1px solid',
      borderColor: 'rgba(0,0,0,0.2)',
      background: 'none',
      transition: 'border-color 0.25s ease, box-shadow 0.25s ease',
      resize: 'vertical',
      ':focus': {
        outline: 'none',
        borderColor: 'border',
        boxShadow: '0 0 0 3px rgba(0,0,0,0.1)'
      }
    },
    select: {
      padding: '10px 15px',
      fontSize: '1.6rem',
      fontWeight: 'light',
      fontFamily: 'body',
      letterSpacing: '0.1rem',
      borderRadius: '5px',
      border: '1px solid',
      borderColor: 'rgba(0,0,0,0.2)',
      background: 'none',
      transition: 'border-color 0.25s ease, box-shadow 0.25s ease',
      resize: 'vertical',
      ':focus': {
        outline: 'none',
        borderColor: 'border',
        boxShadow: '0 0 0 3px rgba(0,0,0,0.1)'
      }
    }
  }
}
